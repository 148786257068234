<template>
  <div class="luocha">
    <div class="luocha-left">
      <div class="left-tab">
        <span
          v-for="(item, i) in tabList"
          :class="selectTabObj.id == item.id ? 'select-tab' : ''"
          @click="selectTabFun(item, item.id)"
          :key="i"
        >
          {{ item.name }}
        </span>
      </div>
      <el-form :style="{ 'margin-top': '10px' }">
        <el-form-item v-if="selectTabObj.id == 1">
          <el-select
            style="width:100%"
            v-model="areaCode"
            placeholder="请选择"
            size="small"
            filterable
            value-key="en"
          >
            <el-option
              v-for="(item, i) in areaCodeList"
              :key="i + item.phone_code"
              :label="item.cn + '(+' + item.phone_code + ')'"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            v-model="searchForm.search"
            clearable
            :placeholder="selectTabObj.memo"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input size="small" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input size="small" placeholder="请输入用户昵称"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            class="el-form-btn"
            type="primary"
            size="small"
            @click="submitFun"
          >
            提交
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="el-form-btn1" size="small" @click="viewAll">
            查看所有历史记录
          </el-button>
        </el-form-item>
      </el-form>
      <div
        class="left-log"
        :style="{
          height:
            selectTabObj.id == 1 ? 'calc(100% - 190px)' : 'calc(100% - 150px)'
        }"
      >
        <div class="left-log-title">
          <span>
            近十条查询记录
          </span>
        </div>
        <div class="log-content">
          <ul>
            <li
              v-for="(item, i) in logList"
              :key="i"
              @click="selectLog(item, i)"
            >
              <i v-for="(ele, i) in tabList" :key="i">
                <i v-if="ele.id == item.target_type">{{ ele.name }}:</i>
              </i>
              <span :class="logIndex == i ? 'select-log' : ''">
                {{ item.target }}
              </span>
            </li>
          </ul>
        </div>
        <no-data
          text="暂无查询记录"
          v-show="logList.length == 0"
          fontSize="14px"
        />
      </div>
    </div>
    <div class="luocha-right">
      <div class="right-title">
        查询结果
      </div>
      <el-table
        :data="list"
        style="width: 100%;marginTop:2px"
        header-row-class-name="tableheader-parent"
        :height="logIndex == null ? windowHeight - 190 : windowHeight - 150"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '8px 0' }"
        v-loading="loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="child-table-box">
              <div
                v-for="(item, i) in props.row.result"
                :key="i"
                class="listChildren"
                v-show="props.row.result.length > 0"
              >
                <ul>
                  <li>
                    <label>用户id:</label>
                    <span>{{ item.user_id }}</span>
                  </li>
                  <li>
                    <label>姓:</label>
                    <span>{{ item.last_name }}</span>
                  </li>
                  <li>
                    <label>名:</label>
                    <span>{{ item.first_name }}</span>
                  </li>
                  <li>
                    <label>手机号:</label>
                    <span>{{ item.phone }}</span>
                  </li>
                  <li>
                    <label>邮箱:</label>
                    <span>{{ item.email }}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>性别:</label>

                    <span v-if="item.gender == 'female'">女</span>
                    <span v-else-if="item.gender == 'male'">男</span>
                    <span v-else>{{ item.gender }}</span>
                  </li>
                  <li>
                    <label>所在地:</label>
                    <span>{{ item.place }}</span>
                  </li>
                  <li>
                    <label>注册时间:</label>
                    <span>{{ item.register_time | dateChange }}</span>
                  </li>
                  <li>
                    <label>婚姻:</label>
                    <span>{{ item.marry }}</span>
                  </li>
                  <li>
                    <label>国家:</label>
                    <span>{{ item.country }}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>公司:</label>
                    <span>{{ item.company }}</span>
                  </li>
                  <li>
                    <label>生日:</label>
                    <span>{{ item.birthday }}</span>
                  </li>
                  <li>
                    <label>家乡:</label>
                    <span>{{ item.hometown }}</span>
                  </li>
                </ul>
              </div>
              <div v-show="props.row.result.length == 0" class="nolistChildren">
                暂无数据
              </div>
              <!-- <el-table
                :data="props.row.result"
                style="width: 100%"
                header-row-class-name="tableheader-child"
              >
                <el-table-column
                  label="用户id"
                  prop="user_id"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="姓"
                  prop="last_name"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="名"
                  prop="first_name"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="手机号"
                  prop="phone"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="邮箱"
                  prop="email"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="性别"
                  prop="gender"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.gender == 'female'">女</span>
                    <span v-else-if="scope.row.gender == 'male'">男</span>
                    <span v-else>{{ scope.row.gender }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="地点"
                  prop="place"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="注册时间"
                  prop="register_time"
                >
                  <template slot-scope="scope">
                    {{ scope.row.register_time | dateChange }}
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="婚姻"
                  prop="marry"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="国家"
                  prop="country"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="公司"
                  prop="company"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="出生日期"
                  prop="birthday"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="家乡"
                  prop="hometown"
                ></el-table-column>
              </el-table> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="任务目标" prop="target"></el-table-column>
        <el-table-column label="查询类型" prop="target_type">
          <template slot-scope="scope">
            <span v-for="(item, i) in tabList" :key="i">
              <span v-if="item.id == scope.row.target_type">
                {{ item.name }}
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_time">
          <template slot-scope="scope">
            {{ scope.row.create_time | capitalize }}
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="logIndex == null"
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :sizesArr="[20, 50, 100, 200]"
        :pagerCount="5"
        background
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import { logTask, setTask, logDetail } from '@/api/data.js'
import { areaCode } from '@/utils/areaCode.js'
import logCom from '../components/LogCom.vue'
export default {
  data() {
    return {
      areaCodeList: areaCode,
      loading: false,
      tabList: [
        {
          id: 1,
          name: '手机号',
          // memo: '请输入手机号(例:8613523456789)',
          memo: '请输入手机号'
        },
        {
          id: 2,
          name: '邮箱',
          memo: '请输入邮箱'
        },
        {
          id: 3,
          name: '姓名',
          memo: '请输入姓名,姓跟名用" | "隔开(例:张|三)'
        },
        {
          id: 4,
          name: '用户ID',
          memo: '请输入用户ID'
        }
      ],

      selectTabObj: {},
      areaCode: {
        cn: '中国',
        en: 'China',
        phone_code: '86'
      },
      searchForm: {
        search: ''
      },
      // 左侧数据
      logList: [], //记录
      list: [],
      listresult: [],
      leftCurrent: 1,
      leftPageSize: 20,
      leftnomore: true,
      logIndex: null,
      // 右侧数据
      // 当前页
      current: 1,
      // 每页记录数
      limit: 20,
      // 总记录数
      total: 0
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      let arr = value.split('+')
      if (Array.isArray(arr)) {
        return arr[0]
      } else {
        return value
      }
    },
    dateChange(value) {
      if (!value) return ''
      let arr = value.split('.')
      if (Array.isArray(arr)) {
        return arr[0].split('T').join(' ')
      } else {
        return value
      }
    }
  },
  computed: {
    windowHeight() {
      return this.$store.state.windowData.height
    }
  },
  components: {
    logCom
  },

  created() {
    this.getlog()
    this.getData()
    this.selectTabObj = Object.assign({}, this.tabList[0])
  },
  methods: {
    // 左侧日志
    getlog() {
      let obj = {
        page: 1,
        page_size: 10
      }
      logTask(obj).then(res => {
        if (res) {
          this.logList = res.data.data
        }
      })
    },
    // 刷新
    viewAll() {
      this.logIndex = null
      this.current = 1
      let obj = {
        page: this.current,
        page_size: this.limit
      }
      logTask(obj).then(res => {
        this.list = res.data.data
        this.listresult = res.data.data.result
        this.total = res.data.count
      })
    },
    // 查询记录
    getData() {
      this.loading = true
      let obj = {
        page: this.current,
        page_size: this.limit
      }

      logTask(obj)
        .then(res => {
          if (res) {
            this.list = res.data.data
            this.listresult = res.data.data.result
            this.total = res.data.count
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    selectTabFun(row, i) {
      this.selectTabObj = Object.assign({}, row)
    },
    // 提交任务
    submitFun() {
      this.logIndex = null
      if (this.searchForm.search.trim() == '') {
        this.$message({
          type: 'warning',
          message: `请输入${this.selectTabObj.name}`
        })
        return
      }

      if (this.selectTabObj.id == 1) {
        // var validateReg = /^[1-9]{1}\d{10}$/
        // if (!validateReg.test(this.searchForm.search)) {
        //   this.$message({
        //     type: 'warning',
        //     message: `请输入正确的手机号！`
        //   })
        //   return
        // }
        let numberReg = /^\d+(\.\d+)?$/

        if (this.searchForm.search == '') {
          this.$message({
            type: 'warning',
            message: `请输入手机号！`
          })
          return
        } else if (!numberReg.test(this.searchForm.search)) {
          this.$message({
            type: 'warning',
            message: `手机号为数字！`
          })
          return
        }
      } else if (this.selectTabObj.id == 2) {
        var reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/
        if (!reg.test(this.searchForm.search)) {
          this.$message({
            type: 'warning',
            message: `请输入正确的邮箱！`
          })
          return
        }
      }

      let obj = {
        target:
          this.selectTabObj.id == 1
            ? parseInt(this.areaCode.phone_code + this.searchForm.search)
            : this.searchForm.search,
        target_type: this.selectTabObj.id
      }

      this.loading = true
      this.current = 1

      setTask(obj)
        .then(res => {
          if (res.status == 201) {
            this.list = []
            this.list.push(res.data)
            this.total = this.list.length
          }
          this.getlog()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 记录
    selectLog(row, i) {
      this.logIndex = i

      logDetail(row.id).then(res => {
        if (res) {
          this.list = []
          this.list.push(res.data)
          this.current = 1
          this.total = 1
        }
      })
    },
    // 右侧分页
    // 分页
    currentChange(val) {
      this.current = val
      this.getData()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
.luocha {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .luocha-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    .el-input__inner {
      padding: 0 5px !important;
    }
    .el-form-item {
      margin-bottom: 0;
      text-align: center;
    }
    .el-form-btn {
      width: 90% !important;
      border: 0px solid #000000;
      border-radius: 16px;
    }
    .el-form-btn1 {
      width: 90%;
      border: 1px solid #94a0a9;
      background: #94a0a9;
      color: white;
      border-radius: 16px;
    }
    .left-tab {
      width: 100%;
      display: flex;
      justify-content: space-around;
      span {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        line-height: 16px;
        padding: 10px 0;
      }
      .select-tab {
        border-bottom: 2px solid #ff9000;
        color: #ff9000;
      }
    }
    .left-log {
      width: 100%;
      height: calc(100% - 150px);
      overflow: hidden;
      margin-top: 20px;
      position: relative;
      .left-log-title {
        border-bottom: 1px solid #fbf6f6;
        span {
          font-size: 14px;
          color: #2a2a2a;
          line-height: 40px;
          padding-left: 10px;
          border-left: 3px solid #0079d3;
        }
      }
      .log-content {
        margin-top: 10px;
        // padding: 0 10px;
        width: 100%;
        height: calc(100% - 90px);

        overflow: auto;

        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 20px 0;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #444446;
              line-height: 18px;
              cursor: pointer;
              user-select: none;
              padding: 5px 10px;
            }
            i {
              font-size: 12px;
            }
            .select-log {
              background: #efefef;

              border-radius: 12px;
            }
          }
        }
      }
    }
  }
  .luocha-right {
    width: calc(100% - 270px);
    height: 100%;
    overflow: hidden;
    background: white;
    padding: 5px;
    .right-title {
      font-size: 15px;
      padding: 0 10px;
      font-weight: bold;
      color: #355383;
      line-height: 28px;
      border-bottom: 1px solid #e5e5e5;
    }
    .el-table--fit {
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
.listChildren {
  display: flex;
  padding: 10px;
  box-shadow: 0 0 5px 0 rgb(82 63 105 / 40%);
  background: #fff;
  border-radius: 10px;
  margin: 10px;
  ul {
    li {
      margin-bottom: 3px;
      label {
        margin-right: 5px;
      }
    }
  }
}
.nolistChildren {
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #eef4f9;
}

.tableheader-parent th {
  background: #f0f3fa !important;
  padding: 0 !important;
  height: 35px;
}
.tableheader-child th {
  background: #0079d3 !important;
  padding: 5px 0 !important;
  color: white !important;
}
.tableheader-child td {
  background: #1957ad !important;
}
.el-table__expanded-cell {
  padding: 0 !important;
}
.comment-nomore {
  font-size: 12px;
  color: #606060;
  text-align: center;
  list-style: none;
}
</style>
